<template>
  <v-container>
    <v-card
      :loading="loading"
      class="mx-auto"
      width="374"
      elevation="0"
    >
      <v-row justify="center" v-if="records.length">
        <v-col cols="auto" sm="12">
          <v-card-text class="teal--text text--lighten-1 text-center">
            Клиент <b>{{subscriber.firstName}} {{subscriber.lastName}} </b>
            <br><br>

            <v-btn text fab class="mr-4" color="teal lighten-1" elevation="5" :href="`tel:${subscriber.phone}`">
              <v-icon>mdi-phone</v-icon>
            </v-btn>

            <v-btn text fab color="teal lighten-1" @click="recordClientHandler(subscriber)" elevation="5">
              <v-icon>mdi-calendar-edit</v-icon>
            </v-btn>

          </v-card-text>
        </v-col>
      </v-row>


      <v-row justify="center" v-else>
        <v-col cols="auto" sm="12">
          <v-card-text class="text-center">
            загрузка...
          </v-card-text>
        </v-col>
      </v-row>

      <RecordList
        v-if="!loading"
        :load-record-list="loadRecordList"
        :selectable="false"
        :record-list="records"
        :light="true"
      />

    </v-card>
  </v-container>
</template>

<script>
  import dateFilter from "../../filters/dateFilter";
  import RecordList from "../../components/RecordList";

  export default {
    name: "ClientRecords",
    components: {RecordList},
    data: () => ({
      records: [],
      loading: true,
      id: "",
      subscriber: "",
      date: ""
    }),

    methods: {
      async loadRecordList() {
        this.loading = true
        const subscriber = this.id
        const json = await this.authFetch('/record/load-record-list-of-client-for-master', {subscriber})
        if (json.status === 200) {
          this.records = json.records
          this.subscriber = json.records[0].subscriber
        } else if (json.message) {
          this.loading = false
          return
        }
        this.records.sort(this.sortReversRecordListFunc)
        this.loading = false
      },

      recordClientHandler(client) {
        this.$store.commit("setFirstName", client.firstName)
        this.$store.commit("setLastName", client.lastName)
        this.$store.commit("setPhone", client.phone)
        this.$router.push("/choose-datetime-master")
      }
    },

    async mounted() {
      this.id = this.$route.query.id
      this.date = dateFilter(Date.now(), "ISO")
      await this.loadRecordList()
    }
  }
</script>

<style scoped>

</style>
